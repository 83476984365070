import React from 'react';
import SideNav from './SideNav/';
import Dashboard from './Dashboard';
import AddCustomIVR from './CustomIVR/AddIVR';
import Login from './Login/';
import ResetPwd from './ResetPwd/';
import UpdatePwd from './UpdatePwd/';
import VoiceCampaign from './VoiceCampaign/';
import SMSCampaign from './SMSCampaign/';
import UserManagement from './UserManagement';
import UserProfile from './UserManagement/UserProfile';
import CreateUser from './UserManagement/CreateUser';
import CreateGroup from './GroupManagement/CreateGroup';
import CreateLocation from './LocationManagement/CreateLocation';
import CreateTrunk from './TrunkManagement/CreateTrunk';
import CreateDialer from './DialerManagement/CreateDialer';
import CLIManagement from './CLIManagement';
import DialerManagement from './DialerManagement';
import TrunkManagement from './TrunkManagement';
import GroupManagement from './GroupManagement';
import LocationManagement from './LocationManagement';
import AddCLI from './CLIManagement/AddCLI';
import UpdateUser from './UserManagement/UpdateUser';
import UpdateGroup from './GroupManagement/UpdateGroup';
import UpdateCli from './CLIManagement/UpdateCLI';
import UpdateDialer from './DialerManagement/UpdateDialer';
import UpdateTrunk from './TrunkManagement/UpdateTrunk';
import AllocateCli from './CLIManagement/AllocateCLI';
import ArchivedCli from './CLIManagement/ArchivedCLI';
import IVRList from './CustomIVR/ListIVR';
import IvrEdit from './CustomIVR/EditIVR';
import Report from './Report';
import CreditsHistory from './UserManagement/CreditsHistory';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UploadCLIFile from './CLIManagement/UploadCLIFile';
import { createBrowserHistory } from 'history';
import CallDetailsRecords from './CallDetailRecords';
import ResellerSummary from "./ResellerSummary";
import UploadCLIApiFile from './ApiCliManagement/UploadCLIFile';
import ApiCLIManagement from './ApiCliManagement';
import ArchivedApiCli from './ApiCliManagement/ArchivedApiCli';
import AddWebhooks from './Webhooks/AddWebhooks';
import WebhookList from './Webhooks/ListWebhooks'
import UpdateWebhooks from './Webhooks/UpdateWebhooks';
import AddSmsWebhooks from './WebhooksSms/AddWebhooks';
import WebhookSmsList from './WebhooksSms/ListWebhooks'
import UpdateSmsWebhooks from './WebhooksSms/UpdateWebhooks';
import ApiKeySummary from './ApiKeyManagment/ApiKeySummary';
import ListApiKeyHistoricalSummary from './ApiKeyManagment/ApiKeyHistoricalSummary';
import CreateApiKey from './ApiKeyManagment/CreateApiKey';
import ApiKeyList from './ApiKeyManagment/ApiKeyList';
import UpdateApiKey from './ApiKeyManagment/UpdateApiKey';
import DedicatedCLIManagement from './DedicatedCLIManagement';
import ArchivedDedicatedCli from './DedicatedCLIManagement/ArchivedDedicatedCli';
import UploadDedicatedCLIFile from './DedicatedCLIManagement/UploadDedicatedCLIFile';
import SearchBlacklistDND from './VoiceCampaign/SearchBlacklistDND';
import CreateGroupAgent  from './InboundCampaign/CreateGroupAgent';
import GroupAgentList from './InboundCampaign/ListGroupAgent';
import UpdateGroupAgent from './InboundCampaign/UpdateGroupAgent';
import AddInboundCampaign from './InboundCampaign/AddInboundCampaign';
import ListInboundCampaign from './InboundCampaign/ListInboundCampaign';
import HistoricalInboundCampaign from './InboundCampaign/HistoricalInboundCampaign';
import CloneInboundCampaign from './InboundCampaign/CloneInboundCampaign';
import SuperadminListInboundCampaign from './InboundCampaign/SuperadminInboundCampaignList';
import ListTTS from './VoiceCampaign/TTSList';
import EditTTS from './VoiceCampaign/EditTTS';
import CreateSubUser from './UserManagement/CreateSubUser';
import SubUserManagement from './UserManagement/SubUserList';
import UpdateSubUser from './UserManagement/UpdateSubUser';
import SubUserReport from './SubUserReport';
import UserSettingList from './UserManagement/UserSettingList';
import UpdateUserSetting from './UserManagement/UpdateUserSetting';
import BulkGroupUpdation from './GroupManagement/BulkGroupUpdation';
import BulkGroupAddition from './GroupManagement/BulkGroupAddition';
import BulkLocationDelete from './LocationManagement/BulkLocationDelete';
import BulkLocationAddition from './LocationManagement/BulkLocationAdd';

const Body = (props) => {
	const app = props.app;
	// Get the current location.
	const history = createBrowserHistory();
	const location = history.location;
	let homePage = '';
	
	if ((app.userId === '' || typeof app.userId === 'undefined' || app.token === '' || typeof app.token === 'undefined')) {
		if ( location.pathname === '/reset-pwd' ) {
			homePage = <ResetPwd setUserToken={props.setUserToken} app={app} />;
		}
		else if ( location.pathname.includes( '/update-pwd' ) ){
			homePage = <Switch><Route exact path="/update-pwd/:selectedToken" render={(routeprops) => (
				<UpdatePwd selectedToken={routeprops.match.params.selectedToken} app={props.app} {...routeprops} />)
			} />
			</Switch>
		}
		else
		{
			homePage = <Login setUserToken={props.setUserToken} app={app} history={props.history} />;
		}
	} else {
		let sideNav = '';
		sideNav = <SideNav toggleSidebar={props.toggleSidebar} app={app} />;

		homePage = <div className="app-body">
			{sideNav}
			<main className="main">
				<div className="container-fluid">
					<VoiceCampaign app={app} />
					<SMSCampaign app={app} />
					<Switch>
						<Route exact path="/"><Dashboard app={app} /></Route>
						<Route path="/user-list"><UserManagement app={app} /></Route>
						<Route path="/user-profile"><UserProfile app={app} /></Route>
						<Route path="/trunk-list"><TrunkManagement app={app} /></Route>
						<Route path="/group-list"><GroupManagement app={app} /></Route>
						<Route path="/location-list"><LocationManagement app={app} /></Route>
						<Route path="/dialer-list"><DialerManagement app={app} /></Route>
						<Route exact path="/update-user/:selectedUserId" render={(routeprops) => (
							<UpdateUser selectedUserId={routeprops.match.params.selectedUserId} app={props.app} {...routeprops} />)
						} />
						<Route path="/create-user"><CreateUser app={app} /></Route>
						<Route path="/create-dialer"><CreateDialer app={app} /></Route>
						<Route path="/create-trunk"><CreateTrunk app={app} /></Route>
						<Route path="/create-group"><CreateGroup app={app} /></Route>
						<Route path="/create-location"><CreateLocation app={app} /></Route>
						<Route path="/add-custom-ivr"><AddCustomIVR app={app} /></Route>
						<Route path="/report"><Report app={app} /></Route>
						<Route path="/ivr-list"><IVRList app={app} /></Route>
						<Route exact path="/ivr/:id" render={routeprops => (
							<IvrEdit app={app} id={routeprops.match.params.id} />
						)}/>
						<Route path="/add-cli"><AddCLI app={app} /></Route>
						<Route path="/cli-list"><CLIManagement app={app} /></Route>
						<Route path="/archived-cli"><ArchivedCli app={app} /></Route>
						<Route path="/upload-cli"><UploadCLIFile app={props.app} /></Route>
						<Route exact path="/update-cli/:selectedCliId" render={(routeprops) => (
							<UpdateCli selectedCliId={routeprops.match.params.selectedCliId} app={props.app} {...routeprops} />)
						} />
						<Route exact path="/update-dialer/:selectedDialerId" render={(routeprops) => (
							<UpdateDialer selectedDialerId={routeprops.match.params.selectedDialerId} app={props.app} {...routeprops} />)
						} />
						<Route exact path="/update-trunk/:selectedTrunkId" render={(routeprops) => (
							<UpdateTrunk selectedTrunkId={routeprops.match.params.selectedTrunkId} app={props.app} {...routeprops} />)
						} />
						<Route exact path="/update-group/:selectedGroupId" render={(routeprops) => (
							<UpdateGroup selectedGroupId={routeprops.match.params.selectedGroupId} app={props.app} {...routeprops} />)
						} />
						<Route path="/allocate-cli"><AllocateCli app={props.app} /></Route>
						{/* <Route path="/dedicated-cli-list"><DedicatedCLIManagement app={app} /></Route> */}
						{/* <Route path="/archived-dedicated-cli"><ArchivedDedicatedCli app={app} /></Route> */}
						{/* <Route path="/upload-dedicated-cli"><UploadDedicatedCLIFile app={props.app} /></Route> */}
						<Route path="/upload-cli-api"><UploadCLIApiFile app={props.app} /></Route>
						<Route path="/cli-api-list"><ApiCLIManagement app={app} /></Route>
						<Route path="/archived-api-cli"><ArchivedApiCli app={app} /></Route>
						<Route path="/credits-history"><CreditsHistory app={app} /></Route>
						<Route path="/download-report"><Report app={app} /></Route>
						<Route path="/call-detail-records"><CallDetailsRecords app={app} /></Route>
						<Route path="/reseller-summary"><ResellerSummary app={app} /></Route>
						<Route path="/add-webhook"><AddWebhooks app={app} /></Route>
						<Route path="/webhook-list"><WebhookList app={app} /></Route>
						<Route exact path="/update-webhooks/:selectedWebhookId" render={(routeprops) => (
							<UpdateWebhooks selectedWebhookId={routeprops.match.params.selectedWebhookId} app={props.app} {...routeprops} />)
						} />
						<Route path="/add-sms-webhook"><AddSmsWebhooks app={app} /></Route>
						<Route path="/sms-webhook-list"><WebhookSmsList app={app} /></Route>
						<Route exact path="/update-sms-webhooks/:selectedWebhookId" render={(routeprops) => (
							<UpdateSmsWebhooks selectedWebhookId={routeprops.match.params.selectedWebhookId} app={props.app} {...routeprops} />)
						} />
						<Route path="/api-key-summary-list"><ApiKeySummary app={app} /></Route>
						<Route path="/api-key-historical-summary-list"><ListApiKeyHistoricalSummary app={app} /></Route>
						<Route path="/create-api-key"><CreateApiKey app={app} /></Route>
						<Route path="/user-api-key-list"><ApiKeyList app={app} /></Route>
						<Route exact path="/update-api-key/:selectedApiKeyId" render={(routeprops) => (
							<UpdateApiKey selectedApiKeyId={routeprops.match.params.selectedApiKeyId} app={props.app} {...routeprops} />)
						} />
						<Route path="/create-group-agent"><CreateGroupAgent app={app} /></Route>
						<Route path="/group-agent-list"><GroupAgentList app={app} /></Route>
						<Route exact path="/update-group-agent/:selectedGroupId" render={(routeprops) => (
							<UpdateGroupAgent selectedGroupId={routeprops.match.params.selectedGroupId} app={props.app} {...routeprops} />)
						} />
						<Route path="/add-inbound-campaign"><AddInboundCampaign app={app} /></Route>
						<Route path="/inbound-campaign-list"><ListInboundCampaign app={app} /></Route>
                        <Route path="/inbound-campaign-historical-list"><HistoricalInboundCampaign app={app} /></Route>
                        <Route path="/clone-inbound-campaign/:campaignId" render={(routeprops) => (
					      <CloneInboundCampaign campaignId={routeprops.match.params.campaignId} app={props.app} {...routeprops} />)
					    } />

						<Route path="/search-blacklist-dnd"><SearchBlacklistDND app={app} /></Route>
						<Route path="/inbound-superadmin-campaign-summary"><SuperadminListInboundCampaign app={app} /></Route>
                        <Route path="/tts-list"><ListTTS app={app} /></Route>
						<Route path="/edit-tts/:ttsId" render={(routeprops) => (
					      <EditTTS ttsId={routeprops.match.params.ttsId} app={props.app} {...routeprops} />)
					    } />

						<Route path="/create-sub-user"><CreateSubUser app={app} /></Route>
						<Route path="/sub-user-list"><SubUserManagement app={app} /></Route>
						<Route exact path="/update-sub-user/:selectedUserId" render={(routeprops) => (
							<UpdateSubUser selectedUserId={routeprops.match.params.selectedUserId} app={props.app} {...routeprops} />)
						} />

						<Route path="/sub-user-report"><SubUserReport app={app} /></Route>
						<Route path="/user-settings"><UserSettingList app={app} /></Route>
						<Route exact path="/update-user_setting/:selectedIds" render={(routeprops) => (
							<UpdateUserSetting selectedIds={routeprops.match.params.selectedIds} app={props.app} {...routeprops} />)
						} />

						<Route path="/bulk-update-group"><BulkGroupUpdation app={app} /></Route>
						<Route path="/bulk-addition-group"><BulkGroupAddition app={app} /></Route>
						<Route path="/bulk-location-delete"><BulkLocationDelete app={app} /></Route>
						<Route path="/bulk-addition-location"><BulkLocationAddition app={app} /></Route>

					</Switch>

				</div>
			</main>
		</div>;
	}
	return (
		<Router>
			{homePage}
		</Router>
	);
}

export default Body;
