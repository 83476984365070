import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, Modal, Dropdown } from 'react-bootstrap';
import { cloneDeep, isEmpty, isNull } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Notify } from "../../../Helpers/notification.js";

import "../index.css";
class UpdateSubUser extends Component {
	constructor(props) {
		super(props);
		this.logoInput = React.createRef();
		this.state = {
			username: '',
			password: '',
			planId: '',
			confirmPassword: '',
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			credits: '',
			domain: '',
			channels: 0,
			parentChannels: 0,
			userChannels: 0,
			logoInput: this.logoInput,
			logo: '',
			redirectFlag: false,
			userType: '',
			planList: [],
			accountType: '',
			parentAccount: '',
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			channelsAvailable: '',
			sendDate: new Date(),
			logoDomain: false,
			moduleList: [],
			parentModuleList: [],
			moduleId: '',
			locationList: [],
			groupList: [],
			locationId: '',
			groupId: '',
			groupName: '',
			groupRows: [],
			locationRows: [],
			groupsList: '',
			locationsList: '',
			margin: '',
			interval: '',
			threshold: '',
			tier : '',
			isSub : false,
			showSubmission: false,
			setting : false,
			pulseDuration: 0,
			Setting1: false,
			Setting2: false,
			Setting3: false,
			Setting4: false,
			margin1: '',
			interval1: '',
			threshold1: '',
			starting_range1: '',
			ending_range1: '',
			margin2: '',
			interval2: '',
			threshold2: '',
			starting_range2: '',
			ending_range2: '',
			margin3: '',
			interval3: '',
			threshold3: '',
			starting_range3: '',
			ending_range3: '',
			margin4: '',
			interval4: '',
			threshold4: '',
			starting_range4: '',
			ending_range4: '',
			loading: false,
			selectedModules: [],
			selectedModuleId: '',
			ttsCredits : '',
			ttsCreditsFlag : false,
            reportType : '',
			reportDate : ''
		};

	}
	componentDidMount() {
		const apiPath = this.state.apiPath;
        const userId = this.props.selectedUserId;
		const parent = this.props.app.userId;
		// const userId = this.state.userId;
		// const parent = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
                    name: res.name,
					emailid: res.emailid,
					number: res.number,
					address: res.address,
					company: res.company,
					pincode: res.pincode,
					parentAccount: res.accountType,
					userType: res.userType,
					type: res.userType==='user' ? 'User' : 'Reseller',
					accountType: res.accountType,
					account: res.accountType===0 ? 'Promotional': 'Transactional',
					channels: res.channels,
					sendDate: new Date(res.expiryDate),
                    reportType : res.reportType,
					reportDate : ( res.reportDate==="" ? "" : new Date(res.reportDate) )
				})
				
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	
	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	handleSelect = function (selectedItems) {
		const servicesSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			servicesSelected.push(selectedItems[i].value);
		}
		this.setState({
			moduleId: servicesSelected.toString()
		});
	}
	
	handleModuleSelect = () => {
		const { selectedModules, selectedModuleId } = this.state;
        
		console.log("IDD : :"+this.state.selectedModuleId);
		if(this.state.selectedModuleId == 7){
			this.setState({
				ttsCreditsFlag : true
			})
		}

		if (selectedModuleId !== '') {
			if(selectedModules.includes(selectedModuleId))
			{
					Notify.notify('This Module is already selected');
					return;
			}
			const updatedModules = [...selectedModules, selectedModuleId]
			this.setState({
				selectedModules: updatedModules,moduleId:updatedModules.toString()
			});
			
		}
		else {
			Notify.notify("Please select Module");
		}
	};

	handleRemoveModuleRow = (moduleId) => {
		const { selectedModules } = this.state;
		if(moduleId == 7){
			this.setState({
				ttsCreditsFlag : false,
				ttsCredits : ''
			})
		}
		const updatedModules = selectedModules.filter(id => id !== moduleId);
		this.setState({
			selectedModules: updatedModules,
			moduleId:updatedModules.toString()
		});
	};
	
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}
	toggleCheckedChange = (e, name) => {
		this.setState({
			[name]: e.target.checked,
		});
	};
	hideModal = () => {
		this.setState({
			showModal: false,
			redirectFlag: this.state.redirectFlag
		});
	}

	handleAddGroupRow = () => {
		if (this.state.groupId !== '') {
			let groupName = this.state.groupId.substring(this.state.groupId.lastIndexOf(',')+1,this.state.groupId.length);
			let groupId = this.state.groupId.substring(0,this.state.groupId.lastIndexOf(','));
			const item = {
				groupId: groupId,
				groupName: groupName
			};
			const checkGroup = obj => obj.groupId === groupId;
			if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}
			this.state.groupRows.push(item);
			this.setState({
				groupRows: this.state.groupRows
			});
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		else {
			Notify.notify("Please select Group");
		}
	};

	handleAddLocationRow = () => {
		if (this.state.locationId !== '') {
			let locationName = this.state.locationId.substring(this.state.locationId.lastIndexOf(',')+1,this.state.locationId.length);
			let locationId = this.state.locationId.substring(0,this.state.locationId.lastIndexOf(','));
			const item = {
				locationId: locationId,
				locationName: locationName
			};
			const checkLocation = obj => obj.locationId === locationId;
			if(this.state.locationRows.some(checkLocation))
			{
					Notify.notify('This Location is already selected');
					return;
			}
			this.state.locationRows.push(item);
			this.setState({
				locationRows: this.state.locationRows
			});
			this.state.locationsList = {
				"locationsList": this.state.locationRows
			}
		}
		else {
			Notify.notify("Please select Location");
		}
	};

	handleRemoveGroupRow = (index) => {
		const newGroupRows = [...this.state.groupRows];
		newGroupRows.splice(index, 1);
		this.setState({
			groupRows: newGroupRows
		});
		this.state.groupsList = {
			"groupsList": newGroupRows
		}
	};

	handleRemoveLocationRow = (index) => {
		const newLocationRows = [...this.state.locationRows];
		newLocationRows.splice(index, 1);
		this.setState({
			locationRows: newLocationRows
		});
		this.state.locationsList = {
			"locationsList": newLocationRows
		}
	};

	handleChangeGroupRow = idx => e => {
		const { name, value } = e.target;
		const groupRows = [...this.state.groupRows];
		groupRows[idx] = {
			[name]: value
		};
		this.setState({
			groupRows
		});
	};

	handleChangeLocationRow = idx => e => {
		const { name, value } = e.target;
		const locationRows = [...this.state.locationRows];
		locationRows[idx] = {
			[name]: value
		};
		this.setState({
			locationRows
		});
	};

	updateUser = (event) => {
		event.preventDefault();
		this.setState({ loading: true });
		const state = cloneDeep(this.state);
	
        console.log("Here12");

		const date = state.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " 23:59:59"
        console.log("report Type : "+this.state.reportType);

		console.log("Report Date : "+ this.state.reportDate);

		const reportdate = this.state.reportDate;
		let reportDateTime ="";
		if(reportdate){
			console.log("hhomes" );
		   reportDateTime = this.addZero(reportdate.getFullYear());
		   reportDateTime += "-";
		   reportDateTime += this.addZero((reportdate.getMonth() + 1));
		   reportDateTime += "-"
		   reportDateTime += this.addZero(reportdate.getDate());
		   reportDateTime += " 00:00:00"
		}

	
		const data = {
                userId: this.props.selectedUserId,
				name: state.name,
				emailid: state.emailid,
				number: state.number,
				address: state.address,
				company: state.company,
				pincode: state.pincode,
				accountType: "0",
				userType: "sub_user",
				expiryDate: sendDateTime,
                reportType : this.state.reportType,
				reportDate : reportDateTime
			}
			this.saveData(data);
		
	}


	saveData = (data) => {
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				if(res.message === 'Profile Updated Successfully')
				{
					this.setState({
						redirectFlag: true
					})
				}
				else {
					this.setState({
						loading: false 
					});
                   // Notify.notify(res.message);
				} 
			})
			.catch(err => {
				console.log('Error in Registering Account', err);
				this.setState({
					loading: false 
				});
				Notify.notify('Error in Registering Account');
			});

	}

	setMessageShow = (status) => {
		this.setState({
			showModal: false,
		});
	}

	sendReportDateChange = date => {
		this.setState({
			reportDate: date
		});
	};


	render() {
		if (this.state.redirectFlag === true) {
			return <Redirect to={'/sub-user-list/'} />
		}
		

		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
			const sendLater = this.state.sendLater;
			let sendLaterDatepicker = '';
			sendLaterDatepicker = 
			<Col sm={4}>
				<Form.Group className="form-group expiry required control-label">
					<Form.Label>User Expiry</Form.Label>
					<DatePicker
						className="form-control"
						selected={this.state.sendDate}
						minDate={new Date()}
						timeCaption="Time"
						dateFormat="yyyy-MM-dd"
						onChange={this.sendLaterDateChange}
						filterTime={filterTime}
					/>
				</Form.Group>
			</Col>

			let sendReportDatepicker = '';
			sendReportDatepicker = 
			<Col sm={4}>
				<Form.Group className="form-group expiry required control-label">
					<Form.Label>Report Date</Form.Label>
						<DatePicker
									className="form-control"
									selected={this.state.reportDate}
									minDate={new Date()}
									timeCaption="Time"
									dateFormat="yyyy-MM-dd"
									onChange={this.sendReportDateChange}
									filterTime={filterTime}
									placeholderText="Select Report Date"
						/>
				</Form.Group>
			</Col>

			
		return (
			<Form method="post" onSubmit={this.updateUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update Sub User</Card.Header>
							<Card.Body>
								{/* <Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} autoComplete="off" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} autoComplete="new-password"/>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								</Row> */}
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue(e)} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="pincode" type="number" placeholder="Pincode" onChange={e => this.setvalue(e)} value={this.state.pincode} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="userType" value="sub_user" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Customer Type</option>
												<option value="sub_user">Sub User</option>
											</Form.Select>
										</Form.Group>
									</Col>

									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="reportType" value={this.state.reportType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Report Type</option>
												<option value="1">DTMF</option>
                                                <option value="2">Call Patch</option>
                                                <option value="3">Both</option>
											</Form.Select>
										</Form.Group>
									</Col>

                                    {(this.props.app.role === 'ROLE_ADMIN' || this.props.app.role === 'ROLE_USER') && <Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" name="accountType" hidden="true" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												{(this.state.parentAccount === 0) && <option value="0">Promotional</option>}
												{(this.state.parentAccount === 1) && <option value="1">Transactional</option>}
											</Form.Select>
										</Form.Group>
									</Col>}

                                    {/* <Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="reportType" value={this.state.reportType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Report Type</option>
												<option value="1">DTMF</option>
                                                <option value="2">Call Patch</option>
                                                <option value="3">Both</option>
											</Form.Select>
										</Form.Group>
									</Col> */}
									
						
								</Row>
								
								<Row>
									{sendLaterDatepicker}
									{sendReportDatepicker}
								</Row>
								
								<Button className="btn-round" variant="primary" type="submit" disabled={this.state.loading}>Update User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateSubUser;